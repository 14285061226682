import axios from "axios";

export const baseUrl = "https://dev.liquitech.co";

const getToken = () => {
  const params = new URLSearchParams(window.location.search)
  if (params.get('apiKey')) {
    return `${params.get('apiKey').toString()}`
  } else {
    return ''
  }
}

const API = axios.create({
  baseURL: `${baseUrl}/api_urls/`,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": getToken(),
  },
});

API.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error?.code === 'ERR_NETWORK') {
      return Promise.reject({
        message: 'Por favor, compruebe su conexión a Internet'
      })
    }

    if (error?.code?.status === '504') {
      return Promise.reject({
        message: 'Tiempo de espera agotado'
      })
    }

    if (error?.code?.status === '401') {
      return Promise.reject({
        message: 'No autorizado.'
      })
    }

    return Promise.reject(error)
  }
)

export default API;