import { Result, Space } from 'antd'
import React from 'react'

const Empty = () => {
    return (
        <Space className='error-container'>
            <Result
                title="No tienes ningún documento pendiente por firmar."
                status="success"
            />
        </Space>
    )
}

export default Empty